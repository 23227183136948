import React from 'react';
import styled from 'styled-components';

const LogoStyles = styled.div`
  max-width: 100px;
  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export default function Logo({ ...rest }) {
  return (
    <LogoStyles {...rest}>
      <h1 style={{ color: 'white' }}>CriminalMW</h1>
    </LogoStyles>
  );
}
