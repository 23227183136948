import React from 'react';
import styled from 'styled-components';
import {
  RiImageEditLine,
  RiHeartsFill,
  RiMoneyCnyBoxLine,
  Ri24HoursLine,
  RiCoinsLine,
} from 'react-icons/ri';
import { MdPhotoCamera } from 'react-icons/md';
import SectionTitle from '../titles/SectionTitle';
import ServiceItem from './ServiceItem';

const ServicesSectionStyles = styled.div`
  padding: 10rem 0;
  text-align: center;
  .services__title {
    margin-bottom: 3rem;
  }
  .services__items {
    display: flex;
    gap: 2rem;
  }
  @media only screen and (max-width: 768px) {
    .services__items {
      flex-direction: column;
      max-width: 300px;
      margin: 0 auto;
    }
  }
`;

function ServicesSection() {
  return (
    <ServicesSectionStyles id="services">
      <div className="container">
        <SectionTitle className="services__title">Mecanismos</SectionTitle>
        <div className="services__items">
          <ServiceItem
            icon={<RiMoneyCnyBoxLine />}
            title="Tela Falsa"
            desc="O bot insere uma tela falsa do própio banco para o bico não ver o bot operando e não desconfiar de absolutamente nada"
          />
          <ServiceItem
            icon={<Ri24HoursLine />}
            title="Device Logs"
            desc="Veja saldos bancários e todo processo do bot utilizando o mecanismo de Device Logs"
          />
          <ServiceItem
            icon={<RiCoinsLine />}
            title="ATS"
            desc="Transfira todo valor da conta da pessoa em segundos apenas fazendo ela abrir o própio banco"
          />
        </div>
      </div>
    </ServicesSectionStyles>
  );
}

export default ServicesSection;
