import React from 'react';
import styled from 'styled-components';
import ParagraphText from '../paragraphTexts/ParagraphText';
import SectionTitle from '../titles/SectionTitle';
import TeamMemberItem from './TeamMemberItem';
// team member images
import teamMember1 from '../../assets/images/team1.png';
import teamMember2 from '../../assets/images/team2.png';
import teamMember3 from '../../assets/images/team3.png';

const TeamSectionStyles = styled.div`
  padding: 10rem 0;
  .team__wrapper {
    display: flex;
    gap: 1rem;
  }
  .team__info {
    max-width: 250px;
  }
  .team__subtitle {
    margin-bottom: 1rem;
    font-weight: 600;
    color: var(--mediumSlateBlue);
    text-transform: capitalize;
  }
  .team__members {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 5rem;
  }
  @media only screen and (max-width: 790px) {
    .team__wrapper {
      flex-direction: column;
      text-align: center;
      gap: 3rem;
    }
    .team__info {
      margin: 0 auto;
    }
    .team__members {
      flex-wrap: wrap;
      gap: 2rem;
    }
  }
`;

function TeamSection() {
  return (
    <TeamSectionStyles>
      <div className="container">
        <div className="team__wrapper">
          <div className="team__info">
            <ParagraphText className="team__subtitle">
              Equipe Criminal
            </ParagraphText>
            <SectionTitle className="team__title">
              Sem eles, nada disso seria possível
            </SectionTitle>
          </div>
          <div className="team__members">
            <TeamMemberItem
              img="https://cdn.discordapp.com/attachments/1149012703847915563/1156330771259347015/9cc1d840-ad61-46d8-ae9a-64e9d25ee0d7.jpeg"
              name="sickoDevz"
              title="CEO & Developer"
            />
            <TeamMemberItem
              img="https://cdn.discordapp.com/attachments/1149012703847915563/1156336330310164541/d1939cfb-8f3b-4316-86bf-61cd649f5993.png"
              name="Pereira"
              title="Administrador"
            />
            <TeamMemberItem
              img="https://cdn.discordapp.com/attachments/1149012703847915563/1156331167633658037/363073508_1321996001857427_6387063675569460976_n.png"
              name="Flyn"
              title="Administrador"
            />
          </div>
        </div>
      </div>
    </TeamSectionStyles>
  );
}

export default TeamSection;
